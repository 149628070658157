<template>
  <v-dialog v-model="dialog" persistent max-width="430px">
    <v-card class="discount-modal">
      <v-card-title>
        <span class="discount-modal__title">Ajouter une réduction</span>
        <v-btn fab dark color="info" text @click="$emit('closeModal')">
          <v-icon dark large> mdi-window-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="discount-modal__body">
          <v-form
            class="form"
            @submit.prevent="submit"
            v-model="valid"
            ref="form"
          >
            <div class="form__body">
              <v-text-field
                v-if="typeDiscount === 'fix'"
                v-model.number="model.discount_fixed"
                outlined
                label="Pour faire une remise"
                type="text"
                persistent-placeholder
                dense
                placeholder="Pour faire une remise"
                :rules="[]"
              ></v-text-field>
              <v-text-field
                v-else
                v-model.number="model.discount_percentage"
                outlined
                label="Pour faire une remise"
                type="text"
                persistent-placeholder
                dense
                placeholder="Pour faire une remise"
                :rules="[]"
              ></v-text-field>

              <div>
                <v-radio-group row v-model="typeDiscount">
                    <v-radio label="Fix" value="fix"></v-radio>
                    <v-radio label="Percantage" value="percent"></v-radio>
                  </v-radio-group>
              </div>

              <div class="text-center">
                <v-btn
                  color="info"
                  depressed
                  type="submit"
                  class="form__submit"
                >
                  {{ $t("userActions.confirm") }}
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import validations from "@/validations/index";
export default {
  name: "DiscountModal",
  props: {
    show: {
      type: Boolean,
    },
    prices: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      typeDiscount: 'fix',
      model: {
        discount_fixed: 0,
        discount_percentage: 0,
      },
      valid: false,
    };
  },
  watch: {
    prices(newVal){
      this.typeDiscount = newVal?.item?.discountPriceValue?.data?.discount_type
      if (newVal?.item?.discountPriceValue?.data?.discount_type === 'percent') {
        this.model.discount_percentage = newVal.item.discountPriceValue.data.discount_number
      } else {
        this.model.discount_fixed = newVal.item.discountPriceValue.data.discount_number
      }
    },
    show: {
      immediate: true,
      handler(newVal) {
        this.dialog = newVal;
        this.isFixed = this.prices.discount_percentage === null
      },
    },
    typeDiscount(value) {
      if (value === 'percent') {
        this.model.discount_fixed = null
      }
      if (value === 'fix') {
        this.model.discount_percentage = null
      }
    }
  },
  computed: {
    validations() {
      return validations;
    },
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (!this.valid) return;

      let formData = {
        item: this.prices.item.item,
        discount_fixed: this.model.discount_fixed,
        discount_percentage: this.model.discount_percentage,
      };

      this.$emit("updatePrice", formData);
      this.$emit("closeModal");
    },
  }
};
</script>

<style src="./DiscountModal.scss" lang="scss" scoped></style>